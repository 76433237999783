// Regular Expressions are from https://github.com/ruimarinho/google-libphonenumber
// From file: https://github.com/ruimarinho/google-libphonenumber/blob/master/src/metadata.js
// MIT, Apache 2.0 licenses

// The actual npm library itself is not used because it adds an additonal 400+kb to the package on build.
// As of 2020 this is too large a file size.  If future conditions permit use an npm package.

// The initial intent of this file was, for the sake or ease and accuracy, to use the number validation expressions from google-libphonenumber.
// The number validation verifies whether the number itself is feasible for a country eliminating more false positives.
// Using the validation expressions was easy when you parsed all special characters out of the node.
// The problem is the returned results don't allow for easy string replacement because of the modification.

// Instead more simplistic formats d{3}d{3}d{4} from google-libphonenumber are used.
// These have to be modified with references to spacing and parentheses characters where relevant.

// The existing regex expressions are not perfect and might generate false positives if there is a similar format.
// Any sufficiently long sequence of digits without spaces, or spaces in the proper places will qualify.

// Potential Issues:
// Different cultures may use different spacing characters which are not currently accounted for

// Additional notes:
  // https://stackoverflow.com/questions/123559/how-to-validate-phone-numbers-using-regex
  // Used for reference to help build US. Might have future value due to the fact it covers extensions.
  // 1: /^(?:(?:\+?1\s*(?:[.-]\s*)?)?(?:\(\s*([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9])\s*\)|([2-9]1[02-9]|[2-9][02-8]1|[2-9][02-8][02-9]))\s*(?:[.-]\s*)?)?([2-9]1[02-9]|[2-9][02-9]1|[2-9][02-9]{2})\s*(?:[.-]\s*)?([0-9]{4})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/g

  // Australian reference
  // https://stackoverflow.com/questions/39990179/regex-for-australian-phone-number-validation

  // Swiss reference
  // https://gist.github.com/peyerluk/8357036
  // swissPhoneRegex: /^(0041|041|\+41|\+\+41|41)?(0|\(0\))?([1-9]\d{1})(\d{3})(\d{2})(\d{2})$/

// spaces, periods, bullets, hypen-like characters
const spacingCharacters = '((?:[\\s.\\-\\xAD\\xB7\\uFF0D\\u0020\\u2010\\u2011\\u2013\\u2014\\u2015\\u2022\\u2027\\u2043\\u2063\\u2212]|%20)*)?';

const regionPrefix = {
  // Optional extension with region code: 1, +1
  1: new RegExp(['(\\+?1?)', spacingCharacters].join('')),
  // The Swiss trunk code zero can have parens if lead by region code... (see bodySequence 41)
  // 0041, 041, ++41, +41, 41, 41, 0041, 041, ++41, +41, 41
  // 41: new RegExp(['(\\+?\\+?(41)?)', spacingCharacters, '[(]?0?[)]?', spacingCharacters].join('')),
  // 61, +61
  61: new RegExp(['(\\+?(61)?)', spacingCharacters].join('')),
};

const bodySequence = {
  // Should 7 digit phonenumbers \\d{7} be handled?
  // 1 and 0 cannot start a NA area code
  1: new RegExp([
    '[(]?([2-9]\\d{2})[)]?', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{4})',
  ].join(''), 'g'),
  // ...but no parens are used if region code is not present, hence the trunk code may also be lumped. (see regionPrefix 41)
  41: new RegExp([
    // Swiss, German, and Austrian regex are combine due to requirements of an ipromote client
    // Swiss formatting has peculiar rules regarding how a zero is used between the national code and body
    // This requires combining national prefix and the body sequence into a unitary expression
    // https://gist.github.com/peyerluk/8357036
    // fphilipe
    // Valid
    // ++41(0)783268674, ++41783268674, +41783268674 0783268674 41783268674
    // Invalid
    // 783268674 (0)783268674 ++410783268674
    // Currently this regex generates false positives likely due to the structure of German expressions
    // False positives aren't a concern in this regard because they are unlikely to occur in a live environment
    // At a later time it would be prudent to refactor this section but as of 4/2021 DevHub only supports 3 regions

    // International Format?
    // Required by centeral European numbers with no specified national prefix
    // Github Issue 11627
    '((\\d{4})', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{3}))',
    '|(',
      // German/Austrian
      '(',
        '(\\+?43)', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{2})', spacingCharacters, '(\\d{2})',
      ')',
      '|(',
        '(\\+?43)', spacingCharacters, '(\\d{4})', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{2})',
      ')',
    ')',
    // Swiss
    '|((?:(?:|0{1,2}|\\+{0,2})41)?)', '(', spacingCharacters, '([\\(]?[0]?[\\)]?))', spacingCharacters, '([1-9]\\d{1})', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{2})', spacingCharacters, '(\\d{2})',
  ].join(''), 'g'),
  61: new RegExp([
    // Avoid consuming the 1 from the 61 of the area code as part of the number
    '(?<!6)',
    // Longer formats must be before shorter formats otherwise a false negative with occur
    '(?:',
      '((\\d{3})', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{4}))',
      '|((\\d{4})', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{3}))',
      '|(([(]?0?\\d[)]?)', spacingCharacters, '(\\d{4})', spacingCharacters, '(\\d{4}))',
      '|(([(]?0?\\d[)]?)', spacingCharacters, '(\\d{2})', spacingCharacters, '(\\d{3})', spacingCharacters, '(\\d{3}))',
    ')',
  ].join(''), 'g'),
};

export default { regionPrefix, bodySequence };
