// new number validation system uses Regular Expressions from google-libphonenumber to validate numbers
// https://github.com/ruimarinho/google-libphonenumber
// MIT, Apache 2.0 licenses

const regularExpressions = require('./regularExpression.js').default;

const validateNumber = (number, region, nodeType) => {

  const regionDialingCode = {
    US: 1,
    CA: 1,
    PR: 1,
    CH: 41,
    AU: 61,
  };

  const dialingCodeBodyRegEx = regularExpressions.bodySequence[regionDialingCode[region]];
  const dialingCodeRegionRegEx = regularExpressions.regionPrefix[regionDialingCode[region]];
  // Replace special characters with a space to decrease regex load? 
  // Unless the replace function is magnitudes faster than the regex there is likely no benefit.

  if (!dialingCodeBodyRegEx) {
    console.log('Country code provided to number changer is either invalid or not supported');
    return false;
  }

  if (nodeType === 'text') {
    return RegExp(dialingCodeBodyRegEx).exec(number);
  }
  if (nodeType === 'anchor') {
    // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/RegExp/source
    // Doesn't use global flag at end of expression
    if (region === 'CH') {
      // For Swiss numbers 41 77, and 077 are permissible, but not 41 077
      // Hence the region prefix and body content are combined
      // At a later time it would be prudent to refactor this section but as of 4/2021 DevHub only supports 3 regions
      const anchorRegex = new RegExp(['^', dialingCodeBodyRegEx.source, '(?: *x(\\d+))?\\s*$'].join(''));
      return RegExp(anchorRegex).exec(number);
    } else {
      const anchorRegex = new RegExp(['^', dialingCodeRegionRegEx.source, dialingCodeBodyRegEx.source, '(?: *x(\\d+))?\\s*$'].join(''));
      return RegExp(anchorRegex).exec(number);
    }
  }
  console.log('Unsupported nodeType');
  return false;
};

export default validateNumber;
