/* eslint-disable camelcase, consistent-return, no-undef, no-useless-concat, no-plusplus,  prefer-destructuring  */
// camelcase: variables defined elsewhere can not be renamed
// consistent-return: arrow function has nothing to return
// no-undef: ctnUniqueId, ctnConfig, utm_ci, ipromote_utm_channel are defined elsewhere
// no-useless-concat: linter does not like old method of string concat
// no-plusplus: used for iterating counter
// prefer-destructuring: no substainial benefit in readability or performance from destructuring the array

// File is structured this way to make it testable in jest.
// module export syntax used as jest spy can only test functions that are called as object methods.

// https://medium.com/@DavideRama/mock-spy-exported-functions-within-a-single-module-in-jest-cdf2b61af642
// https://stackoverflow.com/questions/52650367/jestjs-how-to-test-function-being-called-inside-another-function
const validateNumber = require('./number_validation/number_validation.js').default;

const numberChangerProperties = {
  didRewrite: false,
  url: document.URL,
  region: ctnConfig.region || 'US',
  // should numbers within content be replaced if added after initial replacement
  replaceAfterDomChanges: typeof ctnConfig.replaceAfterDomChanges !== 'undefined' ? ctnConfig.replaceAfterDomChanges : true,
  main: () => {
    const uri = numberChangerProperties.url.split('?')[1];
    if (uri && uri.includes(ctnConfig.override)) {
      return;
    }
    if (ctnConfig.mode === 'query') {
      numberChangerProperties.query(uri);
    } else if (ctnConfig.mode === 'lookup') {
      numberChangerProperties.lookup(uri);
    }
  },
  query: (uri) => {
    if (uri && uri.includes('utm_channel')) {
      let res;
      const endpoint = (ctnConfig.cookies && typeof ipromote_utm_channel !== 'undefined') ? ctnConfig.endpoint + '?utm_ci=' + utm_ci + '&utm_channel=' + ipromote_utm_channel : ctnConfig.endpoint + '?' + uri;
      const xhr = new XMLHttpRequest();
      xhr.open('GET', endpoint);
      xhr.onload = () => {
        if (xhr.status === 200) {
          try {
            res = JSON.parse(xhr.response);
          } catch (e) {
            res = JSON.deserialize(xhr.response);
          }
          if (res.rp) {
              console.log(res.rp);
          } else {                    
              window.sessionStorage.setItem(ctnUniqueId + '_number=', res.number);
              window.sessionStorage.setItem(ctnUniqueId + '_href=', res.href);
              window.sessionStorage.setItem(ctnUniqueId + 'ctn_pre=', 'true');
              return numberChangerProperties.rewriteDocumentOnload();
          }
        } else if (xhr.status === 404) {
          if (window.sessionStorage.getItem(ctnUniqueId + 'ctn_pre=') === 'true') {
            numberChangerProperties.rewriteDocumentOnload();
          }
        }
      };
      xhr.send();
    } else if (window.sessionStorage.getItem(ctnUniqueId + 'ctn_pre=') === 'true') {
      numberChangerProperties.rewriteDocumentOnload();
    }
  },
  lookup: (uri) => {
    // assumed object structure of ctnConfig
    // default_number may or may not be present
    // leading 0 used by many international formats requires a different number be used for display and dialing
    // When an international number is used a national code followed by a zero will create an invalid phone number
    // {
    //   "mode": "lookup",
    //   "region": "US",
    //   "region_code": 1,
    //   "numbers": [
    //       {
    //           "matching_parameters": {
    //               "utm_source": "google",
    //               "utm_campaign": "paidads"
    //           },
    //           "number": "(206) 555-5555"
    //           "number_href": "2065555555"

    //       },
    //       {
    //           "matching_parameters": {
    //               "utm_source": "bing"
    //           },
    //           "number": "(206) 666-6666"
    //           "number_href": "2066666666"
    //       }
    //   ],
    //   "default_number": "(206) 777-7777"
    //   "default_number_href": "2067777777"
    // }
    let number;
    let href;
    const searchParams = new URLSearchParams(window.location.search);
    for (const object of ctnConfig.numbers) {
      let match = true;
      for (const property in object.matching_parameters) {
        // match functions on AND, every property must be true
        if (searchParams.get(property) !==  object.matching_parameters[property]) {
          match = false;
          break;
        };
      }
      // first object that matches will be applied and loop will be exited
      // it is possible multiple objects might match, later matches will be skipped
      if (match === true) {
        number = object.number;
        href = object.number_href;
        break;
      }
    }
    // if no match use default number
    // assumes default_number is present
    // if default_number is not present will generate undefined error
    if (!number && ctnConfig.default_number) {
      number = ctnConfig.default_number;
      href = ctnConfig.default_number_href;
    }
    if (number) {
      window.sessionStorage.setItem(ctnUniqueId + '_number=', number);
      window.sessionStorage.setItem(ctnUniqueId + '_href=', href);
      window.sessionStorage.setItem(ctnUniqueId + 'ctn_pre=', 'true');
      return numberChangerProperties.rewriteDocumentOnload();
    }
  },
  rewriteAnchorNode: (node) => {
    // In the future should set a variable that checks for a region code and defaults to US
    const hrf = node.href;
    const i = hrf.indexOf('tel:') + 4;
    const num = hrf.substring(i);
    const validTitle = validateNumber(node.title, numberChangerProperties.region, 'text');
    if (validTitle) {
      const ctnPhoneNumber = window.sessionStorage.getItem(ctnUniqueId + '_number=');
      // numberChangerProperties.parseCookiePhoneNumber('_number=');
      const start = node.title.substring(0, validTitle.index);
      const end = node.title.substring(validTitle.index + validTitle[0].length);
      // const replacementPhoneNumber = numberChangerProperties.parseCookiePhoneNumber('_number=');
      if (validTitle && ctnPhoneNumber) {
        node.title = start + ctnPhoneNumber + end;
        numberChangerProperties.rewriteTextNode(node); // Check for more than one number in the node
      }
    }

    const validHrefNumber = validateNumber(num, numberChangerProperties.region, 'anchor');
    if (!validHrefNumber) return; // no match

    const tel = window.sessionStorage.getItem(ctnUniqueId + '_href=');
    // numberChangerProperties.parseCookiePhoneNumber('_href=');
    if (!tel) return; // number unchanged
    node.href = 'tel:' + tel;
  },
  rewriteTextNode: (node) => {
    // In the future should set a variable that checks for a region code and defaults to US
    const number = node.nodeValue;
    const validNumber = validateNumber(node.nodeValue, numberChangerProperties.region, 'text');
    if (!validNumber) return; // no match
    const startNumber = validNumber.index;
    const start = number.substring(0, startNumber);
    const endNumber = validNumber.index + validNumber[0].length;
    const end = number.substring(endNumber);
    // validateNumber code finds number sequences of phone number length
    // This check appects or rejects matches on the following premises
    // If there is no trailing character
    // or trailing character is punctuation or space
    // \u00a0 is a non breaking space, however there might be other forms of spaces.
    // \u000A is a line feed, why people use this character I don't know, but they do.
    // \u0009 tab
    // \u0029 )
    // prevents mid number replacement scenarios, such as if a developer is using number hashes for a url
    // https://www.soscisurvey.de/tools/view-chars.php is an excellent resources for identifying character
    // copy from console html and paste
    const endMatch = number.substring(endNumber, endNumber + 1).match(/[?!,'"\.\u00a0\u000A\u0009\u0029\u200B\u2002 ]/);
    const startCharacterIsSpace = number.substring(start.length-1, start.length).match(/[\s.\-\xAD\xB7\uFF0D\u0020\u2010\u2011\u2013\u2014\u2015\u2022\u2027\u2043\u2063\u2212]/);
    // endMatch is null if there is no following character,
    // therefore endNumber is checked against node length
    if ( endNumber === node.length
      || endMatch ) {
      const replacementPhoneNumber = window.sessionStorage.getItem(ctnUniqueId + '_number=');
      if (validNumber && replacementPhoneNumber) {
        // if leading character is a space or no leading character
        if (startCharacterIsSpace
          || start.length === 0) {
          node.nodeValue = start + replacementPhoneNumber + end;
        } else {
        // if leading character and no space, insert space
          node.nodeValue = start + ' ' + replacementPhoneNumber + end;
        }
        numberChangerProperties.rewriteTextNode(node); // Check for more than one number in the node
      }
    };
    /*
    Rejects 123334445555 because the regularExpression.js recognizes the sequence is too long
    Will still accept 12-333-444-5555
    because 333-444-555 and 2-333-444-5555 are valid matches.
    There are ways to handle these edge cases if absolutely necessary,
    but it increases the complexity load of the regex

    There is an edge case where an invalid number before a valid number in the same element will prevent a proper match.
    The number changer does not check the rest of the node.
    This is probably the loop in replaceAllNumbers breaking.
    */
  },
  replaceAllNumbers: (node) => {
    if (!node) return;
    if (node.nodeName === 'SCRIPT' || node.nodeName === 'STYLE') return;
    switch (node.nodeName) {
      case '#text':
        numberChangerProperties.rewriteTextNode(node);
        break;
      case 'A':
        numberChangerProperties.rewriteAnchorNode(node);
        break;
      default: // node type not supported
    }
    for (let i = 0; i < node.childNodes.length; i++) {
      const nn = node.childNodes.length;
      numberChangerProperties.replaceAllNumbers(node.childNodes[i]);
      if (node.childNodes.length !== nn) {
        i++;
      }
    }
  },
  rewriteDocumentOnload: () => {
    if (document.readyState !== 'loading') {
      numberChangerProperties.loadRewrite();
    } else {
      document.onreadystatechange = () => {
        if (document.readyState === 'interactive') {
          numberChangerProperties.loadRewrite();
        }
      };
    }
    if(numberChangerProperties.replaceAfterDomChanges === true) {
      // enable mutation observer
      numberChangerProperties.domChange();
    }
  },
  loadRewrite: () => {
    if (!numberChangerProperties.didRewrite) {
      numberChangerProperties.didRewrite = true;
      numberChangerProperties.replaceAllNumbers(document);
    }
  },
  domChange: () => {
    const config = { attributes: true, childList: true, subtree: true };
    // Mutation observer is compatable IE11+
    const observer = new MutationObserver((mutations) => {
      mutations.forEach((mutation) => {
        if (mutation.type === 'childList' && mutation.addedNodes.length) {
          numberChangerProperties.replaceAllNumbers(mutation.target);
        }
      });
    });
    observer.observe(document, config);
  },
};

// These blocks of code are what allow for testing
// prevent script from self invoking and allows for testing when true
if (window.sb && window.sb.test !== true) {
  numberChangerProperties.main();
}

export default numberChangerProperties;
